@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  scroll-behavior: smooth;
  min-height: 100%;
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}